<template>
    <div id="main-body">
        <div class="show-question-title"></div>
        <q-card class="my-card" flat id="q-top">
            <div class="row items-center no-wrap" style="width: 100%;">
                <div class="col">
                    <div style="display:flex;" class="items-center no-wrap">
                        <img src="../assets/imgaes/dati-logo.png" class="logo gt-sm">
                        <button class="prev dati-btn gt-xs" @click="prevBtn()" :disabled="$store.state.active === 0">
                            {{$t('home.Prev')}}
                        </button>
                        <button class="next dati-btn gt-xs" @click="nextBtn()"
                                :disabled="$store.state.active === ListByExamId.length-1"
                        >
                            {{$t('home.Next')}}
                        </button>
                        <button class="prev dati-btn xs" @click="prevBtn()" :disabled="$store.state.active === 0">
                            <q-icon name="navigate_before"/>
                        </button>
                        <button class="next dati-btn xs" @click="nextBtn()"
                                :disabled="$store.state.active === ListByExamId.length-1"
                        >
                            <q-icon name="navigate_next"/>
                        </button>
                        <div class="clock xs" v-if="$route.meta.type != 'result' ">
                            <div class="clock-time">{{ examTime}}</div>
                            <div class="progressbar" :style="{'width': examprogres}">
                                <div class="clock-time">{{ examTime}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto dati-right">
                    <q-btn-toggle
                            class="toggle gt-xs"
                            v-model="$store.state.paperLanguage"
                            unelevated
                            text-color="white"
                            toggle-text-color="#666666"
                            toggle-color="white"
                            @input="toggle"
                            :options="[
                                {label: 'En', value: 'en'},
                                {label: '中', value: 'zh'},
                              ]"
                    />
                    <button class="dati-btn jieshu gt-xs" v-if="$route.meta.type != 'result' " @click="persistent = true">{{$t('home.EndTest')}}</button>
                    <button class="dati-btn jieshu gt-xs" v-if="$route.meta.type == 'result' "  @click="$router.go(-1)">{{$t('home.Return')}}</button>
                    <div class="clock gt-xs" v-if="$route.meta.type != 'result' ">
                        <div class="clock-time">{{ examTime}}</div>
                        <div class="progressbar" :style="{'width': examprogres}">
                            <div class="clock-time">{{ examTime}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </q-card>
        <q-card class="my-card" flat id="q-content">
            <!--选择题-->
            <ChoiceQuestion ref="ChoiceQuestion" v-if="qusTab && qusType === 1" :qusData="qusData"></ChoiceQuestion>
            <!--多择题 || 判断题 -->
            <MultipleChoice ref="MultipleChoice" v-if="qusTab && qusType === 2" :qusData="qusData"></MultipleChoice>
            <JudgmentQuestion ref="JudgmentQuestion" v-if="qusTab && qusType === 3" :qusData="qusData"></JudgmentQuestion>
            <!--填空题-->
            <supplementQuestion ref="supplementQuestion" v-if="qusTab && qusType === 5" :qusData="qusData"></supplementQuestion>
            <!--拖拽题-->
            <DragQuestion ref="DragQuestion" v-if="qusTab && qusType === 4" :qusData="qusData"></DragQuestion>
        </q-card>
        <pagination ref="pagination"></pagination>
        <!--结束考试弹窗-->
        <q-dialog v-model="persistent" persistent transition-show="scale" transition-hide="scale">
            <q-card class="text-white jeishukaoshi" style="width: 300px;height: 156px">
                <q-card-section>
                    <div class="text-h6">{{$t('home.Prompt')}}</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    {{$t('home.AreYouSureToEndTest')}}
                </q-card-section>

                <q-card-actions align="right" class="text-teal" >
                    <q-btn flat class="jieshu-quxiao"  :label="$t('home.Cancel')" v-close-popup/>
                    <q-btn flat class="jieshu-end" :label="$t('home.End')" @click="gameOver(true)"/>
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- 到时间结束考试 -->
        <q-dialog v-model="persistentTine" persistent transition-show="scale" transition-hide="scale">
            <q-card class="text-white jeishukaoshi" style="width: 300px;height: 156px">
                <q-card-section>
                    <div class="text-h6">{{$t('home.Prompt')}}</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <!-- {{$t('home.AreYouSureToEndTest')}} -->
                    考试已结束
                </q-card-section>

                <q-card-actions align="right" class="text-teal">
                    <!-- <q-btn flat class="jieshu-quxiao" :label="$t('home.Cancel')" v-close-popup/> -->
                    <q-btn flat class="jieshu-end" label="查看成绩" @click="chakanJieGuo()"/>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
    import {QSpinnerOval} from 'quasar'
    import pagination from '../components/pagination'
    import ChoiceQuestion from "../components/ChoiceQuestion";
    import MultipleChoice from "@/components/MultipleChoice"
    import DragQuestion from "@/components/DragQuestion"
    import supplementQuestion from "@/components/supplementQuestion"
    import JudgmentQuestion from "@/components/JudgmentQuestion"
    import {mapState} from 'vuex'

    let endTime = 0
    let downTime = null
    export default {
        name: "Exam",
        components: {
            pagination,
            ChoiceQuestion,
            MultipleChoice,
            DragQuestion,
            supplementQuestion,
            JudgmentQuestion
        },
        data() {
            return {
                qusData: [], //题目数组
                persistent: false,
                question: [],
                subQuestion: [],
                option: [],
                answerOption: [], // 问题循环
                optionArr: [], // 选项循环
                group: '',
                list: [1, 2, 34, 4, 54, 5],
                examTime: 7200,
                examTimeNum: 7200,
                practice_id: '',
                qusTab: true,
                qusType: 1,
                persistentTine: false
            }
        },
        created() {
            if (localStorage.getItem('exam_id') == null && this.$route.meta.type != 'result') {
                this.starDaTi()
                let time = new Date().getTime()
                endTime = (localStorage.getItem('exam_time') * 60 * 1000) + time
                localStorage.setItem('starTime', time)
                localStorage.setItem('endTime', endTime)
                this.examCountDown()
            } else {
                if (this.$route.meta.type == 'result') {
                    this.$nextTick(() => {
                        this.$refs.pagination.getResult(this.$route.query.exam_id, this.$route.query.practice_id)
                    })
                } else {
                    this.$nextTick(() => {
                        this.$refs.pagination.getQuestionList(this.$route.query.id, localStorage.getItem('practice_id'))
                    })
                    this.examCountDown()
                }
            }
        },
        mounted() {
            if (this.$route.meta.type != 'result'){
                window.history && window.history.pushState && (history.pushState(null, null, document.URL),
                    window.addEventListener("popstate", this.back, !1)),
                    window.addEventListener("beforeunload", this.unload)
                let _this = this
                window.onbeforeunload = function (e) {
                    if (_this.$route.name == "exam") {
                        e = e || window.event;
                        // 兼容IE8和Firefox 4之前的版本
                        if (e) {
                            e.returnValue = '关闭提示1111';
                        }
                        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
                        return '关闭提示222';
                    } else {
                        window.onbeforeunload = null
                    }
                };
            }

        },
        methods: {
            // 切换语言
            toggle(value){
                console.log(value)
                this.$i18n.locale = value
            },
            // 开始答题接口
            starDaTi() {
                this.$http.post('question/start_exam', {
                    exam_id: this.$route.query.id,
                    user_id: localStorage.getItem('user_id'),
                    type: this.$route.query.type
                }).then(res => {
                    console.log('开始答题成功')
                    this.$refs.pagination.getQuestionList(this.$route.query.id, res.data.practice_id)
                    localStorage.setItem('exam_id', this.$route.query.id,)
                    localStorage.setItem('practice_id', res.data.practice_id)
                })
            },
            // 考试时间倒计时
            examCountDown() {
                let that = this
                downTime = setInterval(() => {
                    let chaTime = localStorage.getItem('endTime') - new Date().getTime()
                    var h = Math.floor(chaTime / 1000 / 60 / 60 % 24);
                    var m = Math.floor(chaTime / 1000 / 60 % 60) + h * 60;
                    var s = Math.floor(chaTime / 1000 % 60);
                    if (m <= 9) {
                        m = '0' + m
                    }
                    if (s <= 9) {
                        s = '0' + s
                    }
                    if (parseFloat(that.examTimeNum) <= 0) {
                        clearInterval(downTime)
                        that.gameOver(false)
                        that.persistentTine = true
                        that.examTime = '00:00'
                        that.examTimeNum = 0
                    } else {
                        that.examTime = m + ':' + s
                        that.examTimeNum = m + '.' + s
                    }
                }, 1000)

            },
            gameOver(type) {
                let overTime = new Date().getTime()
                let starTime = localStorage.getItem('starTime')
                console.log(overTime - starTime)
                let chaTime = overTime - starTime
                // let chaTime = 1000
                console.log('开始时间', starTime)
                console.log('结束时间', overTime)
                console.log('已经过去的时间', chaTime)
                var $moment = this.$moment.duration(chaTime / 1000, 'second');
                console.log('转换的时间', $moment)
                let minutes = $moment.minutes() > 9 ? $moment.minutes() : '0' + $moment.minutes()
                let seconds = $moment.seconds() > 9 ? $moment.seconds() : '0' + $moment.seconds()
                if (minutes) {
                    console.log('分钟', minutes)
                }
                if (seconds) {
                    console.log('秒', seconds)
                }
                let answer_time = minutes + ":" + seconds
                console.log(answer_time)
                this.$http.post('question/end_exam', {
                    answer_time: answer_time,
                    practice_id: localStorage.getItem('practice_id')
                }).then(res => {
                    console.log(res)
                    this.persistent = false
                    this.$q.notify({
                        type: 'positive',
                        position: 'top',
                        timeout: '500',
                        message: `保存成功`
                    })
                    clearInterval(downTime)
                    localStorage.removeItem('exam_id')
                    localStorage.removeItem('starTime')
                    localStorage.removeItem('endTime')
                    localStorage.removeItem('exam_time')
                    if (type) {
                        this.$router.push({path: '/examResult', query: {id: localStorage.getItem('practice_id')}})
                    }

                }).catch(() =>{
                    this.persistent = false
                    this.$q.notify({
                        type: 'positive',
                        position: 'top',
                        timeout: '500',
                        message: `保存成功`
                    })
                    clearInterval(downTime)
                    localStorage.removeItem('exam_id')
                    localStorage.removeItem('starTime')
                    localStorage.removeItem('endTime')
                    localStorage.removeItem('exam_time')
                    if (type) {
                        this.$router.push({path: '/examResult', query: {id: localStorage.getItem('practice_id')}})
                    }
                })
            },
            chakanJieGuo() {
                localStorage.removeItem('exam_id')
                localStorage.removeItem('starTime')
                localStorage.removeItem('endTime')
                localStorage.removeItem('exam_time')
                this.$router.push({path: '/examResult', query: {id: localStorage.getItem('practice_id')}})
            },
            updateQuestion(item, practice_id) {
                this.qusTab = false
                this.$q.loading.show({
                    spinner: QSpinnerOval,
                    spinnerColor: 'orange',
                    spinnerSize: 140,
                    message: this.$t('home.loading')+'...',
                    messageColor: 'back'
                })
                if (this.$route.meta.type == 'result') {
                    this.$http.post('practice/view_question_detail', {
                        practice_id: practice_id, // 练习记录ID
                        question_id: item.question_id, // 试题ID
                    }).then(res => {
                        this.qusData = res.data
                        this.qusTab = true
                        this.qusType = res.data.question_type_id
                        this.$q.loading.hide()
                    }).catch(err => {
                        console.log(err)
                        this.$q.loading.hide()
                    })
                } else {
                    this.$http.post('question/get_question_detail', {
                        question_id: item.question_id,
                        practice_id:localStorage.getItem('practice_id')
                    }).then(res => {
                        console.log(res)
                        this.qusData = res.data
                        this.qusTab = true
                        this.qusType = res.data.question_type_id
                        this.$q.loading.hide()
                    })
                }

            },
            back() {
                this.persistent = true
                window.history && window.history.pushState && (history.pushState(null, null, document.URL),
                    window.addEventListener("popstate", this.back, !1))
            },
            unload(t) {
                return t.preventDefault(),
                    (t = t || window.event).returnValue = !1,
                    !1
            },
            // 下一题
            nextBtn() {
                this.$store.state.active++;
                this.$refs.pagination.showPage()
                this.updateQuestion(this.ListByExamId[this.$store.state.active],this.$route.query.practice_id)
            },
            prevBtn() {
                this.$store.state.active--;
                this.$refs.pagination.showPage()
                this.updateQuestion(this.ListByExamId[this.$store.state.active],this.$route.query.practice_id)
            },
            // 清除
            clearHuiDa(){
                if (this.qusTab && this.qusType === 1){
                    this.$refs.ChoiceQuestion.clearHuiDa()
                }
                if (this.qusTab && this.qusType === 2){
                    this.$refs.MultipleChoice.clearHuiDa()
                }
                if (this.qusTab &&  this.qusType === 3){
                    this.$refs.JudgmentQuestion.clearHuiDa()
                }
                if (this.qusTab && this.qusType === 5){
                    this.$refs.supplementQuestion.clearHuiDa()
                }
                if (this.qusTab && this.qusType === 4){
                    this.$refs.DragQuestion.clearHuiDa()
                }
            },
            setQusTab(){
                this.qusTab = false
                let that = this
                setTimeout(()=>{
                  that.qusTab = true
                  that.qusType = 4
                },1)
            }
        },
        destroyed: function () {
            window.removeEventListener("beforeunload", this.unload),
                window.removeEventListener("popstate", this.goBack, !1)
        },
        computed: {
            ...mapState([
                'ListByExamId'
            ]),
            examprogres() {
                let num = parseFloat(this.examTimeNum);
                let total = parseFloat(localStorage.removeItem('exam_time'));
                if (isNaN(num) || isNaN(total)) {
                    return "-";
                }

                return total <= 0 ? "0%" : (Math.round(num / total * 10000) / 100.00) + "%";
            }
        },
    }
</script>

<style scoped lang="less">
    #main-body {
        background-color: #dedede;
        width: 100%;
        height: 100%;
        position: absolute;

        .logo {
            height: 60px;
            vertical-align: middle;
            margin-right: 20px;
            margin-left: 10px;
        }

        #q-top {
            position: fixed;
            height: 72px;
            top: 20px;
            -moz-border-radius: 10px 10px 0 0;
            -moz-box-shadow: 0 7px 13px rgba(0, 0, 0, .03137254901960784);
            z-index: 999;
            left: 20px;
            right: 20px;
            padding: 0 40px;
            background-color: #fff;
            border-radius: 30px 30px 0 0;
            box-shadow: 0 7px 13px rgba(0, 0, 0, 0.03);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }


        #q-content {
            position: fixed;
            top: 92px;
            bottom: 96px;
            overflow: auto;
            padding: 0;
            background-color: #fafafa;
            left: 20px;
            right: 20px;
            /*border-radius: 0 0 30px 30px;*/
        }


        .toggle {
            width: 104px;
            height: 44px;
            background: #ED711C;
            display: flex;
            justify-content: center;
            border-radius: 8px;

            /deep/ .q-btn {
                width: 50%;
                border-radius: 8px;
            }
        }

        /deep/ .bg-white {
            border: 1px solid #ED711C;
        }

        .dati-right {
            display: flex;
            align-items: center;

            .jieshu {
                width: 104px;
                font-size: 18px;
                height: 44px;
                background: #EEEEEE;
                border: 1px solid #666666;
                margin: 0 20px;
            }
        }

        .clock {
            /*width: 104px;*/
            height: 44px;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid #ED711C;
            position: relative;

            .clock-time {
                padding: 9px 29px;
                font-size: 18px;
                color: #ED711C;
            }

            .progressbar {
                position: absolute;
                /*height: 100%;*/
                left: 0;
                top: 0;
                background: #ED711C;
                overflow: hidden;

                .clock-time {
                    color: #ffffff;
                }
            }
        }
    }

    .form-check-label {
        vertical-align: middle;
    }

    .sub-question input {
        position: inherit;
        vertical-align: middle;
    }

    label {
        display: inline-block;
        max-width: 100%;
        font-weight: 700;
    }

    .sub-question {
        /deep/ p {
            margin: 8px 0;
        }
    }

    #q-nav {
        position: fixed;
        height: 76px;
        bottom: 20px;
        z-index: 999;
        padding: 0;
        background-color: #fafafa;
        /*box-shadow: 0 -7px 13px rgba(0,0,0,0.03);*/
        right: 20px;
        left: 20px;
        border-radius: 0 0 30px 30px;
    }

    .jeishukaoshi {
        background: url("../assets/imgaes/pop-bg.png") no-repeat !important;
    }

    .jieshu-quxiao {
        color: #969696;
        font-size: 14px;
    }

    .jieshu-end {
        color: #ED711C;
        font-size: 14px;
    }

    .text-teal {
        padding-bottom: 0;
    }

    .q-pt-none {
        padding-bottom: 22px;
    }

    @media (max-width: 768px) {
        .dati-btn {
            width: 46px;
            height: 46px;
        }

        #main-body {
            #q-top {
                left: 0;
                right: 0;
                top: 0;
            }

            #q-content {
                left: 0;
                right: 0;
                top: 70px;
                bottom: 76px;

                .content {
                    padding: 20px !important;
                }
            }

            #q-nav {
                bottom: 0;
                right: 0;
                left: 0;
            }
        }

    }
</style>