<template>
<div class="content-body">
  <div class="content" style="padding: 20px 40px" v-if="$store.state.paperLanguage == 'zh'">
    <!--试卷问题问题-->
    <el-card class="box-card" shadow="never" style="margin-bottom: 20px">
      <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_cn"></p>
    </el-card>
    <div class="sub-question">
      <!--题目-->
      <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="qusData.question_sub_cn!=''" >
        <div class="box-card-text" v-html="replaceImg(qusData.question_sub_cn)"></div>
      </el-card>
      <!--选择答案区域-->
      <el-card class="box-card" shadow="never" v-if="selectOrinput">
        <div class="select" v-for="(item,index) in qusData.question_option_cn" :key="index">
          <div style="margin-right: 10px">
            <p>{{item.key}}</p>
          </div>
          <div style="margin-right: 10px">
            <p v-html="item.des"></p>
          </div>
          
          <el-select v-if="qusData.your_answer_jx[index]" v-model="qusData.your_answer_jx[index].value" placeholder="Select" @change="change($event,index)">
            <el-option
                v-for="(items,indexs) in item.values"
                :key="indexs"
                :label="delHtmlTag(items)"
                :value="delHtmlTag(items)">
            </el-option>
          </el-select>
          <el-select v-else v-model="item.value1" placeholder="Select" @change="change($event,index)">
            <el-option
                v-for="(items,indexs) in item.values"
                :key="indexs"
                :label="delHtmlTag(items)"
                :value="delHtmlTag(items)">
            </el-option>
          </el-select>
        </div>
        <div class="shifouzhengque" v-if="$route.meta.type == 'result'">
          <img v-if="qusData.is_correct == 1" src="../assets/imgaes/dui.png" alt="">
          <img v-if="qusData.is_correct == 2 || qusData.is_correct == 3" src="../assets/imgaes/cuo.png" alt="">
        </div>
      </el-card>
      <!--单选题-->
      <el-card class="box-card" shadow="never" v-if="!selectOrinput">
        <div class="form-check" v-for="(item,index) in question_option_cn" :key="index">
          <input type="radio" v-model="checkedValue" :name="'subQuestion'+index" :id="index" :value="item" style="width: 20px; height: 20px;">
          <label :for="index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
            <p v-html="replaceP(item)"></p>
          </label>
        </div>
        <div class="shifouzhengque" v-if="$route.meta.type == 'result'">
          <img v-if="qusData.is_correct == 1" src="../assets/imgaes/dui.png" alt="">
          <img v-if="qusData.is_correct == 2 || qusData.is_correct == 3" src="../assets/imgaes/cuo.png" alt="">
        </div>
      </el-card>

      <div v-if="$route.meta.type == 'result'">{{$t('home.CorrectAnswer')}}:</div>
      <!-- 正确答案 -->
      <el-card class="box-card" shadow="never" style="margin-bottom: 20px" v-if="$route.meta.type == 'result'">
        <div v-if="selectOrinput">
              <div class="select" v-for="(item,index) in qusData.question_option_cn" :key="index">
                    <div style="margin-right: 10px">
                        <p>{{item.key}}</p>
                    </div>
                    <div style="margin-right: 10px">
                        <p v-html="item.des"></p>
                    </div>
                    <!-- <div v-if="qusData.answer_cn[index]">asdadasd</div> -->
                    <el-select   v-model="item.value"  placeholder="Select">
                        <el-option
                                v-for="(items,indexs) in item.values"
                                :key="indexs"
                                :label="delHtmlTag(items)"
                                :value="delHtmlTag(items)">
                        </el-option>
                    </el-select>
                </div>
        </div> 
        <div v-else>
            <!-- 单选题 -->
            <div class="form-check"  v-for="(item,index) in question_option_cn" :key="index">
            <input type="radio" v-model="checkedValueDa" :name="'subQuestion'+index" :id="index" :value="item" style="width: 20px; height: 20px;">
            <label :for="index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
                <p v-html="replaceP(item)"></p>
            </label>
            </div>
        </div>
      </el-card>
      <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="$route.meta.type == 'result'">
        <div>{{$t('home.Explain')}}:</div>
        <div>{{qusData.question_explain_cn}}</div>
      </el-card>
    </div>

    <button v-if="$route.meta.type != 'result'" class="answer" @click="answer">{{$t('home.Answer')}}</button>
  </div>


    <!-- 英文 -->
  <div class="content" style="padding: 20px 40px" v-if="$store.state.paperLanguage == 'en'">
    <!--试卷问题问题-->
    <el-card class="box-card" shadow="never" style="margin-bottom: 20px">
      <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_en"></p>
    </el-card>
    <div class="sub-question">
      <!--题目-->
      <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="qusData.question_sub_en!=''" >
        <div class="box-card-text" v-html="replaceImg(qusData.question_sub_en)"></div>
      </el-card>
      <!--选择答案区域-->
      <el-card class="box-card" shadow="never" v-if="selectOrinput">
        <div class="select" v-for="(item,index) in qusData.question_option_en" :key="index">
          <div style="margin-right: 10px">
            <p>{{item.key}}</p>
          </div>
          <div style="margin-right: 10px">
            <p v-html="item.des"></p>
          </div>
<!-- defaultAnswer -->
          <el-select v-if="qusData.your_answer_jx_en[index]" v-model="qusData.your_answer_jx_en[index].value" placeholder="Select" @change="change($event,index)">
            <el-option
                v-for="(items,indexs) in item.values"
                :key="indexs"
                :label="delHtmlTag(items)"
                :value="indexs+1">
            </el-option>
          </el-select>
          <el-select v-else v-model="defaultAnswer" placeholder="Select" @change="change($event,index)">
            <el-option
                v-for="(items,indexs) in item.values"
                :key="indexs"
                :label="delHtmlTag(items)"
                :value="indexs+1">
            </el-option>
          </el-select>
        </div>
        <div class="shifouzhengque" v-if="$route.meta.type == 'result'">
          <img v-if="qusData.is_correct == 1" src="../assets/imgaes/dui.png" alt="">
          <img v-if="qusData.is_correct == 2 || qusData.is_correct == 3" src="../assets/imgaes/cuo.png" alt="">
        </div>
      </el-card>
      <!--单选题-->
      <el-card class="box-card" shadow="never" v-if="!selectOrinput">
        <div class="form-check" v-for="(item,index) in question_option_en" :key="index">
          <input type="radio" v-model="checkedValueEn" :name="'subQuestion'+index" :id="index" :value="item" style="width: 20px; height: 20px;">
          <label :for="index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
            <p v-html="replaceP(item)"></p>
          </label>
        </div>
        <div class="shifouzhengque" v-if="$route.meta.type == 'result'">
          <img v-if="qusData.is_correct == 1" src="../assets/imgaes/dui.png" alt="">
          <img v-if="qusData.is_correct == 2 || qusData.is_correct == 3" src="../assets/imgaes/cuo.png" alt="">
        </div>
      </el-card>

      <div v-if="$route.meta.type == 'result'">{{$t('home.CorrectAnswer')}}:</div>
      <!-- 正确答案 -->
      <el-card class="box-card" shadow="never" style="margin-bottom: 20px" v-if="$route.meta.type == 'result'">
         <div v-if="selectOrinput">
              <div class="select" v-for="(item,index) in qusData.question_option_en" :key="index">
                    <div style="margin-right: 10px">
                        <p>{{item.key}}</p>
                    </div>
                    <div style="margin-right: 10px">
                        <p v-html="item.des"></p>
                    </div>
                    <!-- <div v-if="qusData.answer_cn[index]">asdadasd</div> -->
                    <el-select   v-model="item.value"  placeholder="Select">
                        <el-option
                                v-for="(items,indexs) in item.values"
                                :key="indexs"
                                :label="delHtmlTag(items)"
                                :value="delHtmlTag(items)">
                        </el-option>
                    </el-select>
                </div>
        </div> 
        <div v-else>
            <!-- 单选题 -->
            <div class="form-check"  v-for="(item,index) in question_option_en" :key="index">
                
            <input type="radio" v-model="checkedValueDaEn" :name="'subQuestion'+index" :id="index" :value="item" style="width: 20px; height: 20px;">
            <label :for="index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
                <p v-html="replaceP(item)"></p>
            </label>
            </div>
        </div>
       

      </el-card>
      <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="$route.meta.type == 'result'">
        <div>{{$t('home.Explain')}}:</div>
        <div>{{qusData.question_explain_en}}</div>
      </el-card>
    </div>

    <button v-if="$route.meta.type != 'result'" class="answer" @click="answer">{{$t('home.Answer')}}</button>
  </div>
</div>
</template>

<script>
export default {
  props:['qusData'],
  name: "MultipleChoice",
  data(){
    return{
      value:'',
      checkedValue:false,
      checkedValueEn:false,
      question_option_cn:[],
      question_option_en:[],
      selectOrinput:false,
      defaultAnswer:'',
      checkedValueDa:'',
      checkedValueDaEn:''
    }
  },
  watch:{
    'qusData':function(){
            console.log('拜年话')
            this.checkedValue = false
            this.checkedValueEn = false
            if (Array.isArray(this.qusData.question_option_cn)){
                if(this.qusData.question_answer_cn){
                    this.qusData.question_answer_cn = this.qusData.question_answer_cn.split(',')
                    this.qusData.question_answer_en = this.qusData.question_answer_en.split(',')
                    this.qusData.question_option_cn.forEach((item,index) =>{
                    if(this.qusData.question_answer_cn[index] == 1){
                        item.value = '正确'
                    }else{
                        item.value = '不正确'
                    } 
                    })
                    this.qusData.question_option_en.forEach((item,index) =>{
                    if(this.qusData.question_answer_cn[index] == 1){
                        item.value = 'TRUE'
                    }else{
                        item.value = 'FALSE'
                    }
                    })
                }
            
            this.selectOrinput = true
        }else {
        console.log('单选-----------------')
        this.question_option_cn = this.qusData.question_option_cn.values
        this.question_option_en = this.qusData.question_option_en.values
        this.selectOrinput = false
        // this.checkedValue = this.qusData.your_answer
            this.checkedValue = this.qusData.your_answer
            this.checkedValueEn = this.qusData.your_answer_en
            if(this.qusData.question_answer_cn == 1){
                this.checkedValueDa = '正确'
            }else{
                this.checkedValueDa = '不正确'
            }
            
        
            if(this.qusData.question_answer_en == 1){
                this.checkedValueDaEn = 'TRUE'
            }else{
                    this.checkedValueDaEn = 'FALSE'
            }
            
        
        console.log('单选',this.question_option_cn,this.question_option_en)
        }
    }
  },
  created() {
    // alert('选择')
    // 判断是否是选择还是单选
    if (Array.isArray(this.qusData.question_option_cn)){
      console.log('多选---------------2')
        this.selectOrinput = true
        if(this.qusData.question_answer_cn){
            // this.qusData.your_answer_jx.forEach(item =>{
            //     item.value = item.value-0
            // })
            // this.qusData.your_answer_jx_en.forEach(item =>{
            //     item.value = item.value-0
            // })
            this.qusData.question_answer_cn = this.qusData.question_answer_cn.split(',')
            this.qusData.question_answer_en = this.qusData.question_answer_en.split(',')
            this.qusData.question_option_cn.forEach((item,index) =>{
               if(this.qusData.question_answer_cn[index] == 1){
                   item.value = '正确'
               }else{
                   item.value = '不正确'
               } 
            })
            this.qusData.question_option_en.forEach((item,index) =>{
            if(this.qusData.question_answer_en[index] == 1){
                   item.value = 'TRUE'
               }else{
                   item.value = 'FALSE'
               }
            })
          }
    }else {
      console.log('单选-----------------')
      this.question_option_cn = this.qusData.question_option_cn.values
      this.question_option_en = this.qusData.question_option_en.values
      this.selectOrinput = false
      this.checkedValue = this.qusData.your_answer
            this.checkedValueEn = this.qusData.your_answer_en
          if(this.qusData.question_answer_cn == 1){
              this.checkedValueDa = '正确'
          }else{
              this.checkedValueDa = '不正确'
          }
        
     
          if(this.qusData.question_answer_en == 1){
              this.checkedValueDaEn = 'TRUE'
          }else{
                this.checkedValueDaEn = 'FALSE'
          }
        
      console.log('123123',this.checkedValueDa)
    }

  },
  methods:{
    delHtmlTag(str){
      return str.replace(/<[^>]+>/g,"");  //正则去掉所有的html标记
    },
    clearHuiDa(){
      this.qusData.question_option_cn.forEach(item=>{
        if (item.value){
          item.value = ''
        }
      })
      this.qusData.question_option_en.forEach(item=>{
        if (item.value){
          item.value = ''
        }
      })
    },
    answer(){
      let answer = []
      if (this.selectOrinput){
        let b = 0
        if (this.$store.state.paperLanguage == 'zh'){
          this.qusData.question_option_cn.forEach(item =>{
            let a = ''
            if (item.value){
              a = item.key+item.value
              b++
            }else {
              a = item.key
            }
            answer.push(a)
          })
        }
        if (this.$store.state.paperLanguage == 'en'){
          this.qusData.question_option_en.forEach(item =>{
            let a = ''
            if (item.value){
              a = item.key+item.value
              b++
            }else {
              a = item.key
            }
            answer.push(a)
          })
        }
        if (b === 0){
          return
        }
      }else {
        if (this.checkedValue == ''){
          return
        }
        console.log(this.checkedValue)
        answer = [this.checkedValue]
      }


      this.$http.post('question/save_answer',{
        question_id:this.qusData.id,
        practice_id:localStorage.getItem('practice_id')-0,
        answer:answer.toString(),
        sign:0
      }).then(res =>{
        console.log(res)
        console.log('自动跳转下一个',this.$store.state.active,this.$store.state.ListByExamId.length)
        if (this.$store.state.active != this.$store.state.ListByExamId.length-1){
          this.$store.state.active++
          this.$parent.$parent.updateQuestion(this.$store.state.ListByExamId[this.$store.state.active])
        }else {
          this.$parent.$parent.persistent = true
        }
      })
    },
    change(e,index){
      console.log(e,index)
      this.qusData.question_option_cn[index].value = e
      console.log(this.qusData.question_option_cn[index].value)
      this.qusData.question_option_en[index].value = e
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped lang="less">
  .form-check{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
</style>