<template>
    <div class="content-body">
        <div class="content" style="padding: 20px 40px" v-if="$store.state.paperLanguage == 'zh'">
            <!--试卷问题问题-->
            <el-card class="box-card" shadow="never" style="margin-bottom: 20px" >
<!--                <img :src="" alt="">-->
                <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_cn"></p>
            </el-card>
            <div class="sub-question">
                <!--题目-->
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="qusData.question_sub_cn!=''">
                    <div class="box-card-text" v-html="replaceImg(qusData.question_sub_cn)"></div>
                </el-card>
                <!--选择答案区域-->
                <el-card class="box-card" shadow="never" >
                    <div class="form-check" v-for="(item,index) in qusData.question_option_cn" :key="index">
                        <input type="radio" v-model="checkedValue" :name="'subQuestion'+index" :id="index" :value="item.key" style="width: 20px; height: 20px;">
                        <label :for="index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
<!--                            <img :src="$store.state.imgHttp+item.values[0]" alt="">-->
                            <p v-html="replaceP(item.values[0])"></p>
                        </label>
                    </div>
                    <div class="shifouzhengque" v-if="$route.meta.type == 'result'">
                        <img v-if="qusData.is_correct == 1" src="../assets/imgaes/dui.png" alt="">
                        <img v-if="qusData.is_correct == 2 || qusData.is_correct == 3" src="../assets/imgaes/cuo.png" alt="">
                    </div>
                </el-card>
                <div v-if="$route.meta.type == 'result'">{{$t('home.CorrectAnswer')}}:</div>
                <el-card class="box-card" shadow="never" style="margin-bottom: 10px" v-if="$route.meta.type == 'result'">
                    <div class="form-check" v-for="(item,index) in qusData.question_option_cn" :key="index">
                        <input type="radio" v-model="qusAnswer" :name="'subQuestionAnswer'+index" :id="'Answer'+index" :value="item.key" style="width: 20px; height: 20px;">
                        <label :for="'Answer'+index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
                            <p v-html="replaceP(item.values[0])"></p>
                        </label>
                    </div>
                </el-card>
                <el-card class="box-card"  shadow="never" v-if="$route.meta.type == 'result'">
                    <div>{{$t('home.Explain')}}:</div>
                    <div>{{qusData.question_explain_cn}}</div>
                </el-card>
            </div>
            <button v-if="$route.meta.type != 'result'" class="answer" @click="answer">{{$t('home.Answer')}}</button>
        </div>
        <div class="content" style="padding: 20px 40px" v-if="$store.state.paperLanguage == 'en'">
            <!--试卷问题问题-->
            <el-card class="box-card" shadow="never" style="margin-bottom: 20px" >
                <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_en"></p>
            </el-card>
            <div class="sub-question">
                <!--题目-->
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="qusData.question_sub_en!=''">
                    <div class="box-card-text" v-html="replaceImg(qusData.question_sub_en)"></div>
                </el-card>
                <!--选择答案区域-->
                <el-card class="box-card" shadow="never">
                    <div class="form-check" v-for="(item,index) in qusData.question_option_en" :key="index">
                        <input type="radio" v-model="checkedValue" :name="'subQuestion'+index" :id="index" :value="item.key" style="width: 20px; height: 20px;">
                        <label :for="index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
                            <p v-html="replaceP(item.values[0])"></p>
                        </label>
                    </div>
                    <div class="shifouzhengque" v-if="$route.meta.type == 'result'">
                        <img v-if="qusData.is_correct == 1" src="../assets/imgaes/dui.png" alt="">
                        <img v-if="qusData.is_correct == 2 || qusData.is_correct == 3" src="../assets/imgaes/cuo.png" alt="">
                    </div>
                </el-card>
                <div v-if="$route.meta.type == 'result'">{{$t('home.CorrectAnswer')}}:</div>
                <el-card class="box-card" shadow="never" style="margin-bottom: 10px" v-if="$route.meta.type == 'result'">
                    <div class="form-check" v-for="(item,index) in qusData.question_option_en" :key="index">
                        <input type="radio" v-model="qusAnswer" :name="'subQuestionAnswer'+index" :id="'Answer'+index" :value="item.key" style="width: 20px; height: 20px;">
                        <label :for="'Answer'+index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
                            <p v-html="replaceP(item.values[0])"></p>
                        </label>
                    </div>
                </el-card>
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="$route.meta.type == 'result'">
                    <div>{{$t('home.Explain')}}:</div>
                    <div>{{qusData.question_explain_en}}</div>
                </el-card>
            </div>
            <button v-if="$route.meta.type != 'result'" class="answer" @click="answer">{{$t('home.Answer')}}</button>
        </div>
    </div>
</template>

<script>
    export default {
        props:['qusData'],
        name: "ChoiceQuestion",
        data(){
            return{
                checkedValue:'',
                qusAnswer:''
            }
        },
        created() {
            console.log(this.qusData)
            this.$nextTick(()=>{
                this.qusAnswer = this.qusData.question_answer_cn
                this.checkedValue = this.qusData.your_answer
                console.log('选项', this.checkedValue)
            })

        },
        methods:{
            answer(){
                if (this.checkedValue == ''){
                    return
                }
                this.$http.post('question/save_answer',{
                    question_id:this.qusData.id,
                    practice_id:localStorage.getItem('practice_id')-0,
                    answer:this.checkedValue,
                    sign:0
                }).then(res =>{
                    console.log(res)
                    console.log(this.$store.state.active )
                    console.log(this.$store.state.ListByExamId.length)
                    if (this.$store.state.active != this.$store.state.ListByExamId.length-1){
                        this.$store.state.active++
                        this.$parent.$parent.updateQuestion(this.$store.state.ListByExamId[this.$store.state.active])
                    }else {
                        this.$parent.$parent.persistent = true
                    }
                })
            },
            clearHuiDa(){
                this.checkedValue = ''
            }
        }
    }
</script>

<style scoped lang="less">
.form-check{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
</style>