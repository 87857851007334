<script src="../../../qinbang-mall/index.js"></script>
<template>
    <div class="content-body">
        <div class="content" style="padding: 20px 40px" v-if="$store.state.paperLanguage == 'zh'">
            <!--试卷问题问题-->
            <el-card class="box-card" shadow="never" style="margin-bottom: 20px">
<!--                <img :src="$store.state.imgHttp+qusData.question_title_cn" alt="">-->
                <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_cn"></p>
            </el-card>
            <div class="sub-question">
                <div class="" id="drag-wrapper" style="width: 824px; height: 333px; margin-left: 0; margin-top: 0;">
                    <div id="drag-body" style="float: right;">
                        <img id="mainimg" :src="mainimg" alt="">
                        <div id="drag-point"
                             style="width: 210px; left: 290px; height: 5px; background-color: red; position: absolute; opacity: 0.6; top: 24px; display: none;"></div>
                        <img src=""
                             data-mark="A"
                             data-axis="17,11"
                             style="top: 30px;left: -153px;"
                             class="drop-item drop-image-actual drop-my"
                        >
                        <div class="drop-text" style="top: 61px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="B"
                             data-axis="30,24"
                             style="top: 71px;left: -153px;"
                             class="drop-item drop-image-actual drop-my"
                        >
                        <div class="drop-text" style="top: 132px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="C" data-axis="29,30"
                             class="drop-item drop-image-actual drop-my"
                             style="top: 142px; left: -153px;">
                        <div class="drop-text" style="top: 197px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="D" data-axis="30,11"
                             class="drop-item drop-image-actual drop-my"
                             style="top: 207px; left: -153px;">
                        <div class="drop-text" style="top: 238px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="E" data-axis="35,30"
                             class="drop-item drop-image-actual drop-my"
                             style="top: 248px; left: -153px;">
                        <div class="drop-text" style="top: 303px; left: -153px; width: 133px;"></div>
                    </div>
                    <div class="shifouzhengque" v-if="$route.meta.type == 'result'">
                        <img v-if="qusData.is_correct == 1" src="../assets/imgaes/dui.png" alt="">
                        <img v-if="qusData.is_correct == 2 || qusData.is_correct == 3" src="../assets/imgaes/cuo.png" alt="">
                    </div>
                </div>

                <div v-if="$route.meta.type == 'result'">{{$t('home.CorrectAnswer')}}:</div>
                <div class="" v-if="$route.meta.type == 'result'" id="drag-wrapper" style="width: 824px; height: 333px; margin-left: 0; margin-top: 0;">
                    <div id="drag-body" style="float: right;">
                        <img id="mainimg" :src="mainimg" alt="">
                        <div id="drag-point"
                             style="width: 210px; left: 290px; height: 5px; background-color: red; position: absolute; opacity: 0.6; top: 24px; display: none;"></div>
                        <img src=""
                             data-mark="A"
                             data-axis="17,11"
                             style="top: 30px;left: -153px;"
                             class="drop-item drop-image-actual drop-nswer"
                        >
                        <div class="drop-text" style="top: 61px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="B"
                             data-axis="30,24"
                             style="top: 71px;left: -153px;"
                             class="drop-item drop-image-actual drop-nswer"
                        >
                        <div class="drop-text" style="top: 132px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="C" data-axis="29,30"
                             class="drop-item drop-image-actual drop-nswer"
                             style="top: 142px; left: -153px;">
                        <div class="drop-text" style="top: 197px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="D" data-axis="30,11"
                             class="drop-item drop-image-actual drop-nswer"
                             style="top: 207px; left: -153px;">
                        <div class="drop-text" style="top: 238px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="E" data-axis="35,30"
                             class="drop-item drop-image-actual drop-nswer"
                             style="top: 248px; left: -153px;">
                        <div class="drop-text" style="top: 303px; left: -153px; width: 133px;"></div>
                    </div>
                </div>
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never"
                         v-if="$route.meta.type == 'result'">
                    <div>{{$t('home.Explain')}}:</div>
                    <div>{{qusData.question_explain_cn}}</div>
                </el-card>

            </div>
            <button class="answer" v-if="$route.meta.type != 'result'" @click="answer">{{$t('home.Answer')}}</button>
        </div>
        <div class="content" style="padding: 20px 40px" v-if="$store.state.paperLanguage == 'en'">
            <!--试卷问题问题-->
            <el-card class="box-card" shadow="never" style="margin-bottom: 20px">
                <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_en"></p>
            </el-card>
            <div class="sub-question">

                <!--        <el-card class="box-card" style="margin-bottom: 20px" shadow="never">-->
                <!--          <div v-html="replaceImg(qusData.question_sub_cn)"></div>-->
                <!--        </el-card>-->

                <div class="" id="drag-wrapper" style="width: 824px; height: 333px; margin-left: 0; margin-top: 0;">
                    <div id="drag-body" style="float: right;">
                        <img id="mainimg" :src="mainimg" alt="">
                        <div id="drag-point"
                             style="width: 210px; left: 290px; height: 5px; background-color: red; position: absolute; opacity: 0.6; top: 24px; display: none;"></div>
                        <img src=""
                             data-mark="A"
                             data-axis="17,11"
                             style="top: 30px;left: -153px;"
                             class="drop-item drop-image-actual drop-my"
                        >
                        <div class="drop-text" style="top: 61px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="B"
                             data-axis="30,24"
                             style="top: 71px;left: -153px;"
                             class="drop-item drop-image-actual drop-my"
                        >
                        <div class="drop-text" style="top: 132px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="C" data-axis="29,30"
                             class="drop-item drop-image-actua drop-my"
                             style="top: 142px; left: -153px;">
                        <div class="drop-text" style="top: 197px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="D" data-axis="30,11"
                             class="drop-item drop-image-actual drop-my"
                             style="top: 207px; left: -153px;">
                        <div class="drop-text" style="top: 238px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="E" data-axis="35,30"
                             class="drop-item drop-image-actual drop-my"
                             style="top: 248px; left: -153px;">
                        <div class="drop-text" style="top: 303px; left: -153px; width: 133px;"></div>
                    </div>
                    <div class="shifouzhengque" v-if="$route.meta.type == 'result'">
                        <img v-if="qusData.is_correct == 1" src="../assets/imgaes/dui.png" alt="">
                        <img v-if="qusData.is_correct == 2 || qusData.is_correct == 3" src="../assets/imgaes/cuo.png" alt="">
                    </div>
                </div>
                <div v-if="$route.meta.type == 'result'">{{$t('home.CorrectAnswer')}}:</div>
                <div class="" v-if="$route.meta.type == 'result'" id="drag-wrapper" style="width: 824px; height: 333px; margin-left: 0; margin-top: 0;">
                    <div id="drag-body" style="float: right;">
                        <img id="mainimg" :src="mainimg" alt="">
                        <div id="drag-point"
                             style="width: 210px; left: 290px; height: 5px; background-color: red; position: absolute; opacity: 0.6; top: 24px; display: none;"></div>
                        <img src=""
                             data-mark="A"
                             data-axis="17,11"
                             style="top: 30px;left: -153px;"
                             class="drop-item drop-image-actual drop-nswer"
                        >
                        <div class="drop-text" style="top: 61px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="B"
                             data-axis="30,24"
                             style="top: 71px;left: -153px;"
                             class="drop-item drop-image-actual drop-nswer"
                        >
                        <div class="drop-text" style="top: 132px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="C" data-axis="29,30"
                             class="drop-item drop-image-actual drop-nswer"
                             style="top: 142px; left: -153px;">
                        <div class="drop-text" style="top: 197px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="D" data-axis="30,11"
                             class="drop-item drop-image-actual drop-nswer"
                             style="top: 207px; left: -153px;">
                        <div class="drop-text" style="top: 238px; left: -153px; width: 133px;"></div>
                        <img src=""
                             data-mark="E" data-axis="35,30"
                             class="drop-item drop-image-actual drop-nswer"
                             style="top: 248px; left: -153px;">
                        <div class="drop-text" style="top: 303px; left: -153px; width: 133px;"></div>
                    </div>
                </div>
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never"
                         v-if="$route.meta.type == 'result'">
                    <div>{{$t('home.Explain')}}:</div>
                    <div>{{qusData.question_explain_en}}</div>
                </el-card>
            </div>
            <button class="answer" v-if="$route.meta.type != 'result'" @click="answer">{{$t('home.Answer')}}</button>
        </div>
    </div>
</template>

<script>
    let m = [63, 72, 81, 89, 98, 107, 115, 125, 134, 142, 151]
    let a, u, c = null
    export default {
        props: ['qusData'],
        name: "DragQuestion",
        data() {
            return {
                value: '',
                mainimg: '',
                answerNr: ''
            }
        },
        created() {
            console.log(this.qusData)

        },
        mounted() {
            this.init()
            this.initOption()
            if (this.qusData.your_answer != '' || this.qusData.your_answer != null){
                this.setMyAnswer()

            }
            this.setMoreDaAn()

        },
        methods: {
            clearHuiDa(){
                // this.init()
                // this.initOption()
                console.log()
                this.$parent.$parent.setQusTab()
                // this.$parent.$parent.setQusTab()
                // console.log(this.$parent.qusTab)
                // let that = this
                // setTimeout(()=>{
                //     that.$parent.qusTab = true
                // },1)
            },
            answer() {
                this.$http.post('question/save_answer', {
                    question_id: this.qusData.id,
                    practice_id: localStorage.getItem('practice_id') - 0,
                    answer: this.answerNr,
                    sign: 0
                }).then(res => {
                    console.log(res)
                    if (this.$store.state.active != this.$store.state.ListByExamId.length-1){
                        this.$store.state.active++
                        this.$parent.$parent.updateQuestion(this.$store.state.ListByExamId[this.$store.state.active])
                    }else {
                        this.$parent.$parent.persistent = true
                    }
                })
            },
            // 初始化选项
            initOption() {
                if (this.$store.state.paperLanguage == 'zh') {
                    this.qusData.question_option_cn.forEach((item, index) => {
                        var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;// img 标签取src里面内容的正则
                        this.$('.drop-my').eq(index).attr('data-mark', item.key)
                        this.$('.drop-my').eq(index).attr('src', item.values[0].replace(b, '$1'))
                        this.$('.drop-nswer').eq(index).attr('data-mark', item.key)
                        this.$('.drop-nswer').eq(index).attr('src', item.values[0].replace(b, '$1'))
                    })
                }
                if (this.$store.state.paperLanguage == 'en') {
                    var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;// img 标签取src里面内容的正则
                    this.qusData.question_option_en.forEach((item, index) => {
                           this.$('.drop-my').eq(index).attr('data-mark', item.key)
                        this.$('.drop-my').eq(index).attr('src', item.values[0].replace(b, '$1'))
                        this.$('.drop-nswer').eq(index).attr('data-mark', item.key)
                        this.$('.drop-nswer').eq(index).attr('src', item.values[0].replace(b, '$1'))
                    })
                }
            },
            init() {
                var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;// img 标签取src里面内容的正则
                var s = this.qusData.question_sub_cn.match(b);// 取到所有img标签 放到数组 s里面
                for (var i = 0; i < s.length; i++) {
                    var srcImg = s[i].replace(b, '$1');//取src面的内容
                    this.mainimg = srcImg
                }
                console.log('111111')
                let that = this
                this.$('#border').droppable({
                    // tolerance:'intersect',
                })
                that.chushihua()
                this.$('.drop-image-actual').draggable({
                    // containment:'#border',
                    start: function () {
                        that.setDrag(that.$(this))
                        a = that.$(this).attr('data-axis').split(',')
                        a = {
                            x: parseInt(a[0]),
                            y: parseInt(a[1])
                        }
                    },
                    drag: function () {
                        u = {
                            left: that.$(this).position().left + a.x,
                            top: that.$(this).position().top + a.y
                        }
                        that.$('#drag-point').css({top: u.top - 2})
                        that.$('#drag-point')[u.top >= 10 && u.top <= 210 && u.left >= 290 && u.left <= 530 ? "show" : "hide"]()
                    },
                    stop: function () {
                        that.$('#drag-point').hide()
                        that.answerNr = that.setMark(that.$(this))
                    }
                })
            },
            // mark 设置
            setMark(e) {
                for (var t = 0; t < m.length; t++) {
                    var i = m[t];
                    if (u.top >= i - 5 && u.top <= i + 5)
                        return e.css({
                            top: i - a.y,
                            left: 395 - a.x
                        }),
                        e.attr("data-mark") + (t + 1)
                }
                return u.top < m[0] ? (e.css({
                    top: m[0] - a.y,
                    left: 395 - a.x
                }),
                e.attr("data-mark") + 1) : u.top > m[10] ? (e.css({
                    top: m[10] - a.y,
                    left: 395 - a.x
                }),
                e.attr("data-mark") + 11) : void 0
            },
            // 拖拽设置
            setDrag(e) {
                let that = this
                console.log(1)
                this.$('.drop-image-actual').each(function () {
                    var t = that.$(this).attr("data-mark");
                    if (e.attr("data-mark") !== t) {
                        that.$(this).css(c[t])
                    }
                })
            },
            chushihua() {
                c = {
                    A: {
                        left: -153,
                        top: 30
                    },
                    B: {
                        left: -153,
                        top: 71
                    },
                    C: {
                        left: -153,
                        top: 142
                    },
                    D: {
                        left: -153,
                        top: 207
                    },
                    E: {
                        left: -153,
                        top: 248
                    }
                }
            },
            // 默认答案设置
            setMoreDaAn() {
                var i = this.qusData.question_answer_cn.substring(0, 1),
                    n = parseInt(this.qusData.question_answer_cn.substring(1, this.qusData.question_answer_cn.length));
                let that = this
                this.$('.drop-nswer').each(function () {
                    let answer =  that.$(this).attr('data-mark')
                    if (answer == i){
                            a = that.$(this).attr("data-axis").split(","),
                            a = {
                                x: parseInt(a[0]),
                                y: parseInt(a[1])
                            }
                            that.$(this).css({
                                top: m[n - 1] - a.y,
                                left: 395 - a.x
                            })
                    }
                })
            },
            // 我选择的答案
            setMyAnswer(){
                var i = this.qusData.your_answer.substring(0, 1),
                    n = parseInt(this.qusData.your_answer.substring(1, this.qusData.your_answer.length));
                let that = this
                this.$('.drop-my').each(function () {
                    let answer =  that.$(this).attr('data-mark')
                    if (answer == i){
                        a = that.$(this).attr("data-axis").split(","),
                            a = {
                                x: parseInt(a[0]),
                                y: parseInt(a[1])
                            }
                        that.$(this).css({
                            top: m[n - 1] - a.y,
                            left: 395 - a.x
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .shifouzhengque{
        right: 26px !important;
    }
</style>