<template>
<div class="content-body">
  <div class="content" style="padding: 20px 40px" v-if="$store.state.paperLanguage == 'zh'">
    <!--试卷问题问题-->
    <el-card class="box-card" shadow="never" style="margin-bottom: 20px">
      <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_cn"></p>
    </el-card>
    <div class="sub-question">
      <!--题目-->
      <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="qusData.question_sub_cn!=''" >
        <div class="box-card-text" v-html="replaceImg(qusData.question_sub_cn)"></div>
      </el-card>
      <!--选择答案区域-->
      <el-card class="box-card" shadow="never">
        <div class="select" v-for="(item,index) in qusData.question_option_cn" :key="index">
          <div style="margin-right: 10px">
            <p>{{item.key}}</p>
          </div>
          <div style="margin-right: 10px">
            <p v-html="item.des"></p>
          </div>
          <el-select v-model="item.value" placeholder="Select" @change="change($event,index)">
            <el-option
                v-for="(items,index) in item.values"
                :key="index"
                :label="delHtmlTag(items)"
                :value="delHtmlTag(items)">
            </el-option>
          </el-select>
        </div>
        <div class="shifouzhengque" v-if="$route.meta.type == 'result'">
          <img v-if="qusData.is_correct == 1" src="../assets/imgaes/dui.png" alt="">
          <img v-if="qusData.is_correct == 2 || qusData.is_correct == 3" src="../assets/imgaes/cuo.png" alt="">
        </div>
      </el-card>


      <div v-if="$route.meta.type == 'result'">{{$t('home.CorrectAnswer')}}:</div>
      <el-card class="box-card" shadow="never" style="margin-bottom: 20px" v-if="$route.meta.type == 'result'">
        <div class="select" v-for="(item,index) in qusData.question_option_cn" :key="index">
          <div style="margin-right: 10px">
            <p>{{item.key}}</p>
          </div>
          <div style="margin-right: 10px">
            <p v-html="item.des"></p>
          </div>
          <!-- <div v-if="qusData.answer_cn[index]">asdadasd</div> -->
         <!-- {{qusData.answer_cn[index]}} -->
          <el-select v-if="qusData.answer_cn[index]"  v-model="qusData.answer_cn[index].values"  placeholder="Select">
            <el-option
                    v-for="(items,indexs) in item.values"
                    :key="indexs"
                    :label="delHtmlTag(items)"
                    :value="delHtmlTag(items)">
            </el-option>
          </el-select>
          <el-select v-else v-model="defaultAnswer" placeholder="Select">
            <el-option
                    v-for="(items,index) in item.values"
                    :key="index"
                    :label="delHtmlTag(items)"
                    :value="delHtmlTag(items)">
            </el-option>
          </el-select>
        </div>
        <!-- 单选题 -->
        <div class="form-check" v-for="(item,index) in question_option_cn" :key="index">
          <input type="radio" v-model="checkedValue" :name="'subQuestion'+index" :id="index" :value="item" style="width: 20px; height: 20px;">
          <label :for="index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
            <p v-html="replaceP(item)"></p>
          </label>
        </div>
      </el-card>
      <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="$route.meta.type == 'result'">
        <div>{{$t('home.Explain')}}:</div>
        <div>{{qusData.question_explain_en}}</div>
      </el-card>
    </div>

    <button v-if="$route.meta.type != 'result'" class="answer" @click="answer">{{$t('home.Answer')}}</button>
  </div>

  <div class="content" style="padding: 20px 40px" v-if="$store.state.paperLanguage == 'en'">
    <!--试卷问题问题-->
    <el-card class="box-card" shadow="never" style="margin-bottom: 20px" >
      <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_en"></p>
    </el-card>
    <div class="sub-question">
      <!--题目-->
      <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="qusData.question_sub_en!=''">
        <div v-html="replaceImg(qusData.question_sub_en)"></div>
      </el-card>
      <!--选择答案区域-->
      <el-card class="box-card" shadow="never" >
        <div class="select" v-for="(item,index) in qusData.question_option_en" :key="index">
          <div style="margin-right: 10px">
            <p>{{item.key}}</p>
          </div>
          <div style="margin-right: 10px">
            <p v-html="item.des"></p>
          </div>
          <el-select v-model="item.value" placeholder="Select">
            <el-option
                    v-for="(items,index) in item.values"
                    :key="index"
                    :label="delHtmlTag(items)"
                    :value="delHtmlTag(items)">
            </el-option>
          </el-select>
        </div>
        <div class="shifouzhengque" v-if="$route.meta.type == 'result'">
          <img v-if="qusData.is_correct == 1" src="../assets/imgaes/dui.png" alt="">
          <img v-if="qusData.is_correct == 2 || qusData.is_correct == 3" src="../assets/imgaes/cuo.png" alt="">
        </div>
      </el-card>
      <!--单选题-->

      <div v-if="$route.meta.type == 'result'">{{$t('home.CorrectAnswer')}}:</div>
      <el-card class="box-card" shadow="never" style="margin-bottom: 20px" v-if="$route.meta.type == 'result'">
        <div class="select" v-for="(item,index) in qusData.question_option_en" :key="index">
          <div style="margin-right: 10px">
            <p>{{item.key}}</p>
          </div>
          <div style="margin-right: 10px">
            <p v-html="item.des"></p>
          </div>
          <el-select v-if="qusData.answer_en[index]" v-model="qusData.answer_en[index].values" placeholder="Select">
            <el-option
                    v-for="(items,index) in item.values"
                    :key="index"
                    :label="delHtmlTag(items)"
                    :value="delHtmlTag(items)">
            </el-option>
          </el-select>
          <el-select v-else v-model="defaultAnswer" placeholder="Select">
            <el-option
                    v-for="(items,index) in item.values"
                    :key="index"
                    :label="delHtmlTag(items)"
                    :value="delHtmlTag(items)">
            </el-option>
          </el-select>
        </div>
      </el-card>
      <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="$route.meta.type == 'result'">
        <div>{{$t('home.Explain')}}:</div>
        <div>{{qusData.question_explain_en}}</div>
      </el-card>
    </div>
    <button v-if="$route.meta.type != 'result'" class="answer" @click="answer">{{$t('home.Answer')}}</button>
  </div>
</div>
</template>

<script>
export default {
  props:['qusData'],
  name: "MultipleChoice",
  data(){
    return{
      value:'',
      checkedValue:false,
      question_option_cn:[],
      question_option_en:[],
      selectOrinput:false,
      defaultAnswer:''
    }
  },
  watch:{
    'qusData':function(){
        
    if (this.qusData.your_answer_jx){
      if (this.qusData.your_answer_jx.length > 0){
        this.qusData.question_option_cn.forEach((item,index)=>{
          item.value = this.qusData.your_answer_jx[index].value
        })
        this.qusData.question_option_en.forEach((item,index)=>{
          item.value = this.qusData.your_answer_jx_en[index].value
        })
      }
    }else {
      console.log('111')
    }
    }
  },
  created() {
    // alert('选择')
    // 判断是否是选择还是单选
    
    if (this.qusData.your_answer_jx){
      if (this.qusData.your_answer_jx.length > 0){
        this.qusData.question_option_cn.forEach((item,index)=>{
          item.value = this.qusData.your_answer_jx[index].value
        })
        this.qusData.question_option_en.forEach((item,index)=>{
          item.value = this.qusData.your_answer_jx_en[index].value
        })
      }
    }else {
      console.log('111')
    }

  },
  methods:{
    delHtmlTag(str){
      return str.replace(/<[^>]+>/g,"");  //正则去掉所有的html标记
    },
    clearHuiDa(){
      this.qusData.question_option_cn.forEach(item=>{
        if (item.value){
          item.value = ''
        }
      })
      this.qusData.question_option_en.forEach(item=>{
        if (item.value){
          item.value = ''
        }
      })
    },
    answer(){
      let answer = []
   
        let b = 0
        if (this.$store.state.paperLanguage == 'zh'){
          this.qusData.question_option_cn.forEach(item =>{
            let a = ''
            if (item.value){
              a = item.key+item.value
              b++
            }else {
              a = item.key
            }
            answer.push(a)
          })
        }
        if (this.$store.state.paperLanguage == 'en'){
          this.qusData.question_option_en.forEach(item =>{
            let a = ''
            if (item.value){
              a = item.key+item.value
              b++
            }else {
              a = item.key
            }
            answer.push(a)
          })
        }
        if (b === 0){
          return
        }
    


      this.$http.post('question/save_answer',{
        question_id:this.qusData.id,
        practice_id:localStorage.getItem('practice_id')-0,
        answer:answer.toString(),
        sign:0
      }).then(res =>{
        console.log(res)
        console.log('自动跳转下一个',this.$store.state.active,this.$store.state.ListByExamId.length)
        if (this.$store.state.active != this.$store.state.ListByExamId.length-1){
          this.$store.state.active++
          this.$parent.$parent.updateQuestion(this.$store.state.ListByExamId[this.$store.state.active])
        }else {
          this.$parent.$parent.persistent = true
        }
      })
    },
    change(e,index){
      console.log(e,index)
      this.qusData.question_option_cn[index].value = e
      console.log(this.qusData.question_option_cn[index].value)
      this.qusData.question_option_en[index].value = e
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped lang="less">
  .form-check{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
</style>