<template>

    <q-card class="my-card" flat id="q-nav">
        <div class="select-container">
            <ul class="pagination" ref="pagination" >
                <li >
                    <a @click="switchPage(true)" aria-label="Previous">
                            <span aria-hidden="true">
                                 <img src="../assets/imgaes/left.png" alt="">
                            </span>
                    </a>
                </li>
                <li ref="pages"
                    v-for="(item,index) in $store.state.ListByExamId" :key="index"
                    @click="updateQuestion(item,index)"
                    :class="{'active':$store.state.active === index , 'answer':item.answer}"
                >

                    <span v-if="$route.query.type == 'exam_ai' || $route.query.type == 'exam_teacher' || exam_type == 'exam_ai' || exam_type == 'exam_teacher'">
<!--                       {{item.question_code}}-->
                        {{index+1}}
                        <img v-if="item.sign == 1" class="flag" src="../assets/imgaes/flag.png"  alt="">
                        <i class="err">
                          <img v-if="item.is_correct != 1 && item.is_correct!='' && $route.path !='/exam' "  src="../assets/imgaes/err.png" alt="">
                        </i>
                    </span>
<!--                    v-if="$route.query.type == 'exam'"-->
                    <span v-else>
                       {{item.question_code}}
                        <!--                        {{index+1}}-->
                        <img v-if="item.sign == 1" class="flag" src="../assets/imgaes/flag.png"  alt="">
                        <i class="err">
                          <img v-if="item.is_correct != 1 && item.is_correct!='' && $route.path !='/exam' "  src="../assets/imgaes/err.png" alt="">
                        </i>
                    </span>
                </li>
                <li >
                    <a @click="switchPage(false)"   aria-label="Next">
                            <span aria-hidden="true">
                                <img src="../assets/imgaes/right.png" alt="">
                            </span>
                    </a>
                </li>
            </ul>
<!--            <ul class="pagination" ref="pagination" v-if="this.$route.query.type == 'exam_teacher'">-->
<!--                <li >-->
<!--                    <a @click="switchPage(true)" aria-label="Previous">-->
<!--                            <span aria-hidden="true">-->
<!--                                 <img src="../assets/imgaes/left.png" alt="">-->
<!--                            </span>-->
<!--                    </a>-->
<!--                </li>-->
<!--                <li ref="pages"-->
<!--                    v-for="(item,index) in $store.state.ListByExamId" :key="index"-->
<!--                    @click="updateQuestion(item,index)"-->
<!--                    :class="{'active':$store.state.active === index , 'answer':item.answer}"-->
<!--                >-->
<!--                    <span>-->
<!--                       {{index+1}}-->
<!--                        <img v-if="item.sign == 1" class="flag" src="../assets/imgaes/flag.png"  alt="">-->
<!--                        <i class="err">-->
<!--                          <img v-if="item.is_correct != 1 && item.is_correct!='' && $route.path !='/exam' "  src="../assets/imgaes/err.png" alt="">-->
<!--                        </i>-->

<!--                    </span>-->
<!--                </li>-->
<!--                <li >-->
<!--                    <a @click="switchPage(false)"   aria-label="Next">-->
<!--                            <span aria-hidden="true">-->
<!--                                <img src="../assets/imgaes/right.png" alt="">-->
<!--                            </span>-->
<!--                    </a>-->
<!--                </li>-->
<!--            </ul>-->
            <div class="operation gt-xs" v-if="$route.meta.type != 'result'">
                <button class="dati-btn" @click="btnFlag()">{{$t('home.Mark')}}</button>
                <button class="dati-btn" @click="clearHuiDa()">{{$t('home.Clear')}}</button>
            </div>
        </div>

    </q-card>
</template>

<script>
    export default {
        name: "pagination",
        data(){
            return{
                activeCurrentId:0,
                exam_type:''
            }
        },
        created() {

        },
        mounted: function() {
            window.addEventListener("resize", this.showPage)
        },
        updated: function() {
            console.log('1111')
            this.showPage()
        },
        methods:{
            // 获取题目列表 question/get_question_practice
            getQuestionList(exam_id,practice_id){
                this.$http.post('question/get_question_practice',{
                    exam_id:exam_id,
                    practice_id:practice_id
                }).then(res =>{
                    console.log(res)
                    this.$store.state.ListByExamId = res.data.info
                    this.$parent.updateQuestion(res.data.info[0])
                    this.showPage()
                })
            },
            // 获取答案列表  practice/view_exam
            getResult(exam_id,practice_id){
                this.$http.post('practice/view_exam',{
                    exam_id:exam_id,
                    practice_id:practice_id
                }).then(res =>{
                    console.log(res)
                    this.$store.state.ListByExamId = res.data.info
                    this.exam_type = res.data.type
                    this.$parent.updateQuestion(res.data.info[0],practice_id)
                    this.showPage()
                })
            },
            // 标记题目
            btnFlag(){
                // getListByExamId.data[this.$store.state.active].flag = !getListByExamId.data[this.$store.state.active].flag
                // console.log(getListByExamId.data[this.$store.state.active].flag)
                // this.getListByExamId = getListByExamId.data
                if (this.$store.state.ListByExamId[this.$store.state.active].sign == 0){

                    this.$http.post('question/sign',{
                        practice_id:localStorage.getItem('practice_id'),
                        question_id:this.$store.state.ListByExamId[this.$store.state.active].question_id,
                        sign:1
                    }).then(res =>{
                        console.log(res)
                        this.$store.state.ListByExamId[this.$store.state.active].sign = 1
                    })
                }else if (this.$store.state.ListByExamId[this.$store.state.active].sign == 1){
                    this.$http.post('question/sign',{
                        practice_id:localStorage.getItem('practice_id'),
                        question_id:this.$store.state.ListByExamId[this.$store.state.active].question_id,
                        sign:0
                    }).then(res =>{
                        console.log(res)
                        this.$store.state.ListByExamId[this.$store.state.active].sign = 0
                    })
                }

                // 标记

            },
            clearHuiDa(){
                this.$parent.clearHuiDa()
            },
            switchPage(t){
                for (
                    var e,
                         i = this.$refs.pagination.offsetWidth,
                         s = this.$refs.pages.length,
                         n = parseInt(i / 41) - 3,
                         a = 0,
                         o = 0;
                         o < s;
                         o++
                ){
                    console.log(o)
                    if ("none" != this.$refs.pages[o].style.display) {
                        a = o;
                        break
                    }

                }
                console.log(e,i,s,n,a,o,t)
                e = t ? a >= n ? a - n : 0 : a + 2 * n <= s ? a + n : s - n;
                console.log('每页展示个数', e)

                for (var l = 0; l < s; l++){
                    console.log(l >= e && l <= e - 1 + n)
                    if (l >= e && l <= e - 1 + n){
                        this.$refs.pages[l].style.display = ""
                    }else {
                        this.$refs.pages[l].style.display = "none"
                    }
                }
            },
            showPage() {
                var t = this.$refs.pagination.offsetWidth
                    , e = this.$refs.pages.length
                    , i = parseInt(t / 41) - 3
                    , s = parseInt(i / 2)
                    , n = this.$store.state.active
                    , a = n >= s ? s : n
                    , o = e - n >= s ? s : e - n;
                    a < s && (o += s - a),
                    o < s && (a += s - o);
                for (var l = n - a, r = n + o, c = 0; c < e; c++){
                    this.$refs.pages[c].style.display = "none",
                    c >= l && c <= r && (this.$refs.pages[c].style.display = "")
                }
            },
            updateQuestion(item,index){
                this.$store.state.active = index
                this.$parent.updateQuestion(item,this.$route.query.practice_id)
            }
        }
    }
</script>

<style scoped lang="less">
    // 分页样式
    .select-container{
        padding: 10px 40px;
        display: flex;
        align-items: center;
    /*justify-content: space-between;*/
    .pagination{
        margin-top: 6px;
        flex: 1;
        .flag{
            position: absolute;
            top: -15px;
            left: 0;
            right: 0;
            margin: auto;
        }
        .err{
            position: absolute;
            top: -27px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .pagination>li{
        display: inline-block;
    }
    .pagination li:hover>span {
        color: #fff
    }

    .pagination li:hover>span {
        background-image: url("../assets/imgaes/pagination-bg.png");
    }

    .pagination li:hover a {
        color: #fff
    }
    .pagination>li>a,.pagination>li>span{
        float: left;
        margin-left: -1px
    }
    .pagination>li>a,.pagination>li>span{
        padding: 6px 12px;
        /*background-color: #fff;*/
        border: 1px solid #999
    }
    .pagination li:hover a {
        background-color: inherit
    }

    .pagination li a,.pagination li span {
        height: 40px;
        width: 40px;
        font-size: 21px;
        color: #999;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pagination li a, .pagination li span {
        padding: 0;
        text-align: center;
        border-color: #ddd;
        cursor: pointer
    }

    .pagination li a span {
        font-size: 21px;
    }

    .pagination .glyphicon-flag {
        display: block;
        color: #000;
        width: 40px;
        height: 20px;
        top: -20px;
        position: absolute;
        line-height: 20px
    }

    .pagination .glyphicon-flag {
        background-color: #ddd
    }

    .pagination .glyphicon-flag {
        left: -1px
    }

    .pagination .glyphicon-flag {
        right: -1px
    }

    .pagination .active:hover span, .pagination .active span {
        background-image: url("../assets/imgaes/pagination-bg.png");
        /*border-color: #ddd*/
        color: #ffffff;
    }

    .pagination .answered span {
        color: #505050
    }

    .pagination .answered span {
        background-color: #91cfbc
    }
    .operation{
        margin-left: 77px;
    }
    }
</style>